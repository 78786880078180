<template>
  <v-main class="pr-0">
    <router-view />

    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },
  computed: {
    ...mapState(["token"]),
  },
  mounted() {
    if (this.token.length == 0 && this.$route.name !== 'SignUp') {
      this.$router.push('/login');
    // } else {
    //   this.$router.push('/pages/Index');
    }
  },
};
</script>
<style lang="sass">
  .v-main
    background: url(../../../../assets/images/index_bg.jpg) no-repeat center center
    background-size: cover
</style>